// ----------------------------------------- \\\
// ---------------- IMPORTS ---------------- \\\
// ----------------------------------------- \\\
import $ from "jquery";



// ----------------------------------------- \\\
// ----------------- VARS ------------------ \\\
// ----------------------------------------- \\\
var $header       	= $('.header');
var $fixed 			= $header.clone().addClass('h-fixed').insertAfter($header);

var prevScrollTop 	= 0;
var breakPoint 		= 200;


// ----------------------------------------- \\\
// ------------------ INIT ----------------- \\\
// ----------------------------------------- \\\
if($header.length){
    $(window).on('scroll.header', onScroll);
	onScroll();

	$('.bt-menu').on('click',function(){
		if($(this).hasClass('open')){
			$(this).removeClass('open');
			$(this).parent().parent().find('.m-desktop').slideUp();
		}else{
			$(this).addClass('open');
			$(this).parent().parent().find('.m-desktop').slideDown();
		}
	});
}



// ----------------------------------------- \\\
// ------------ PUBLIC FUNCIONS ------------ \\\
// ----------------------------------------- \\\



// ----------------------------------------- \\\
// ------------ PRIVATE FUNCIONS ----------- \\\
// ----------------------------------------- \\\

function onScroll(){

	var dHeight 		= $(document).height();
	var wHeight			= $(window).height();
	var scrollTop 		= $(window).scrollTop();
	var isScrollingUp 	= prevScrollTop - scrollTop > 0 ? true : false;

	// Scroll em cima
	if (scrollTop < breakPoint) {
		$fixed.removeClass('force-hide').removeClass('visible');
	}
	//Scroll no bottom da página
	else if (scrollTop + wHeight >= dHeight) {
		$fixed.addClass('visible');
	}
	//Scroll no meio
	else {
		if (isScrollingUp) {
			// $fixed.removeClass('visible').addClass('force-hide');
		} else {
			$fixed.addClass('visible').removeClass('force-hide');
		}
	}
	
	prevScrollTop = scrollTop;
}


