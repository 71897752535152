// ----------------------------------------- \\\
// ---------------- IMPORTS ---------------- \\\
// ----------------------------------------- \\\
import $ from "jquery";

// ----------------------------------------- \\\
// ----------------- VARS ------------------ \\\
// ----------------------------------------- \\\
var $iframe        	= $('.iframe');
var map				= null;


// ----------------------------------------- \\\
// ------------------ INIT ----------------- \\\
// ----------------------------------------- \\\
function init(){

	window.initGmaps = function(){
		
		$iframe.each(function(i,e){
			buildMap(e);
		});
	}

	if ($iframe.is(':visible')) {
		if (window.google && window.google.maps) initGmaps();
		else loadScript('//maps.googleapis.com/maps/api/js?key='+(get('gkey') || 'AIzaSyAM8KXD0yIkBW1zawEuc3iuWcIQT9E2fY0')+'&callback=initGmaps');
	}

}





// ----------------------------------------- \\\
// ------------ PUBLIC FUNCIONS ------------ \\\
// ----------------------------------------- \\\
function resize() {}



// ----------------------------------------- \\\
// ------------ PRIVATE FUNCIONS ----------- \\\
// ----------------------------------------- \\\
function loadScript(src, callback){
	var s = document.createElement('script'), r = false, t;
	s.type = 'text/javascript';
	s.src = src;
	s.onload = s.onreadystatechange = function(){
		if (!r && (!this.readyState || this.readyState == 'complete')) {
			r = true;
			if (typeof callback == 'function') callback();
		}
	}
	t = document.getElementsByTagName('script')[0];
	t.parentNode.insertBefore(s, t);
}

function get(paramKey){

	var pairs = location.search.replace(/^\?/, '').split('&');
	var params = [];

	pairs.forEach(function(item){
		var split = item.split('=');
		var key = split[0];
		var val = split[1];
		params[key] = val;
	});

	var selected = params[paramKey];
	if (selected) selected = decodeURIComponent(selected.replace(/\+/gi, ' '));

	return selected;

}


function buildMap(_iframe){

	var lat = $(_iframe).data('lat');
    var lng = $(_iframe).data('lgn');

    var coords = new google.maps.LatLng(lat, lng);

	var _styles = [
		{
			"featureType": "water",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#e9e9e9"
				},
				{
					"lightness": 17
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#f5f5f5"
				},
				{
					"lightness": 20
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ffffff"
				},
				{
					"lightness": 17
				}
			]
		},
		{
			"featureType": "road.highway",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"color": "#ffffff"
				},
				{
					"lightness": 29
				},
				{
					"weight": 0.2
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#ffffff"
				},
				{
					"lightness": 18
				}
			]
		},
		{
			"featureType": "road.local",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#ffffff"
				},
				{
					"lightness": 16
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#f5f5f5"
				},
				{
					"lightness": 21
				}
			]
		},
		{
			"featureType": "poi.park",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#dedede"
				},
				{
					"lightness": 21
				}
			]
		},
		{
			"elementType": "labels.text.stroke",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#ffffff"
				},
				{
					"lightness": 16
				}
			]
		},
		{
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"saturation": 36
				},
				{
					"color": "#333333"
				},
				{
					"lightness": 40
				}
			]
		},
		{
			"elementType": "labels.icon",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "transit",
			"elementType": "geometry",
			"stylers": [
				{
					"color": "#f2f2f2"
				},
				{
					"lightness": 19
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#fefefe"
				},
				{
					"lightness": 20
				}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"color": "#fefefe"
				},
				{
					"lightness": 17
				},
				{
					"weight": 1.2
				}
			]
		}
	]

	var mapOptions = {
		scaleControl: false,
		draggable: true,
		scrollwheel: false,
		disableDefaultUI: true,
		panControl: true,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		zoom: 14,
		center: new google.maps.LatLng(lat,lng),
		styles: _styles,
	};


  

	map = new google.maps.Map(_iframe, mapOptions);

	var marker = new google.maps.Marker({
	    position: coords,
	    map: map,
        icon: {
            url: BASE_URL+"/public/frontend/img/ico-pin.svg",
            scaledSize: new google.maps.Size(50, 50),
        }

      });

}




// ----------------------------------------- \\\
// ---------------- EXPORTS ---------------- \\\
// ----------------------------------------- \\\
export { init, resize }






