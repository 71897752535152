// ----------------------------------------- \\\
// ---------------- IMPORTS ---------------- \\\
// ----------------------------------------- \\\
import $ from "jquery";
import 'slick-carousel';
import JqueryValidate from "jquery-validation";

// ----------------------------------------- \\\
// ----------------- VARS ------------------ \\\
// ----------------------------------------- \\\
var $homeCulture      	= $('.home-culture');
var $homeProducts     	= $('.home-products');
var $homeWebdoor      	= $('.webdoor');
var $cultureGallery     = $('.culture-gallery');
var $btCultureGal		= $homeCulture.find('.bt-gallery');

var _introVIdeo 		= document.getElementById("intro");

var _webdoorLenght  	= null;


// ----------------------------------------- \\\
// ------------------ INIT ----------------- \\\
// ----------------------------------------- \\\
function init(){


	// CULTURE
    $homeCulture.find('.slides').slick({
		arrows		    : false,
		dots		    : false,
		infinite	    : true,
		variableWidth	: true,
		slidesToShow	: 3,
		responsive: [
			{
			  breakpoint: 768,
			  settings: {
				slidesToShow: 1,
			  }
			}
		]
	});

    $homeCulture.find('.bt-left').on('click',function(){
        $homeCulture.find('.slides').slick('slickPrev');
    });

    $homeCulture.find('.bt-right').on('click',function(){
        $homeCulture.find('.slides').slick('slickNext');
    });

	if($homeCulture.find('.slick-slide').length <= 3){
		$homeCulture.find('.bt-arrow').hide();
	}



	// PRODUCTS
    $homeProducts.find('.slides').slick({
		arrows		    : false,
		dots		    : false,
		infinite	    : true,
		centerMode      : true,
        variableWidth   : false,
	});

    $homeProducts.find('.bt-left').on('click',function(){
        $homeProducts.find('.slides').slick('slickPrev');
    });

    $homeProducts.find('.bt-right').on('click',function(){
        $homeProducts.find('.slides').slick('slickNext');
    });

    $homeProducts.find('.slides').on( 'afterChange', function( e, slick, current ){
		setProductsInfo(current+2);
		console.log(current+2);
	});

	$homeProducts.find('.slides').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		motionInfoOut();
	});

    $homeProducts.find('.slides').slick('slickGoTo', 0);


	// WEBDOOR
	$homeWebdoor.find('.slides').slick({
		arrows		    : false,
		dots		    : false,
		infinite		: true,
		pauseOnHover	: false,
        speed			: 1200,
		autoplay        : false,   
		autoplaySpeed	: 5500,
	});

	_webdoorLenght = $homeWebdoor.find('.slick-slide:not(.slick-cloned)').length;

    $homeWebdoor.find('.bt-left').on('click',function(){
        $homeWebdoor.find('.slides').slick('slickPrev');
    });

    $homeWebdoor.find('.bt-right').on('click',function(){
        $homeWebdoor.find('.slides').slick('slickNext');
    });

	$homeWebdoor.find('.slides').on( 'afterChange', function( e, slick, current, slideCount ){
		var _percent = (current+1) * (100 / _webdoorLenght);
		$homeWebdoor.find('.line').css('width', _percent+'%');		
	});

	$homeWebdoor.find('.slides').slick('slickGoTo', 0);


	// CULTURE GALERRY
	$cultureGallery.find('.bt-close').on('click',function(){
		$cultureGallery.fadeOut();
	});

	$btCultureGal.on('click',function(){
		var _target = $(this).closest('.item').data('target');

		addCultureGallery(_target);

	});



	_introVIdeo.addEventListener('ended',endVideo,false);


	$('.video-intro').find('.bt-play').on('click',function(){
		playVideo();
	});

	$('.video-intro').find('.bt-close').on('click',function(){
		stopVideo();
	});

	if(localStorage.getItem('loaded') == 'yes'){
		$('.video-intro').addClass('hide');
		$homeWebdoor.find('.slides').slick('slickPlay');
	}else{
		$('.video-intro').removeClass('hide');
	}

	localStorage.setItem('loaded', 'yes');


	$('.newsletter').each(validateNewsletter);

}





// ----------------------------------------- \\\
// ------------ PUBLIC FUNCIONS ------------ \\\
// ----------------------------------------- \\\
function resize() {}



// ----------------------------------------- \\\
// ------------ PRIVATE FUNCIONS ----------- \\\
// ----------------------------------------- \\\
function setProductsInfo(_index){
	
	var _name 	    = $homeProducts.find('.item').eq(_index).data('title');
	var _designer 	= $homeProducts.find('.item').eq(_index).data('designer');
	var _year 	    = $homeProducts.find('.item').eq(_index).data('year');
	var _link 	    = $homeProducts.find('.item').eq(_index).data('link');

	$homeProducts.find('.name div').remove();
	$homeProducts.find('.designer').html(_designer);
	$homeProducts.find('.year').html(_year);
	$homeProducts.find('.button').attr('href',_link);

	var _words = _name.split(' ');

	for(var i = 0; i < _words.length; i++){
		$homeProducts.find('.name').append('<div class="words"><span>'+_words[i]+'</span></div>');
	}

	setTimeout(function(){
		motionInfoIn();
	},500);
}

function motionInfoOut(){
	$homeProducts.find('.name').removeClass('motion-in');
	$homeProducts.find('.year').removeClass('motion-in');
	$homeProducts.find('.designer').removeClass('motion-in');

    $homeProducts.find('.button').removeClass('motion-in');

	$homeProducts.find('.words').each(function(i,e){
        setTimeout(function(){
            $(e).removeClass('motion-in');
        },i*20);
    });
}

function motionInfoIn(){
	$homeProducts.find('.name').addClass('motion-in');
	$homeProducts.find('.designer').addClass('motion-in');
	$homeProducts.find('.year').addClass('motion-in');
	$homeProducts.find('.button').addClass('motion-in');

	$homeProducts.find('.name').find('.words').each(function(i,e){
        setTimeout(function(){
            $(e).addClass('motion-in');
        },i*50);
    });
}

function addCultureGallery(_target){

	var $slides = $('[data-gallery="'+_target+'"]');
	$slides.fadeIn();

	if(!$slides.find('.slides').hasClass('slick-initialized')){
		$slides.find('.slides').slick({
			arrows		    : false,
			dots		    : false,
			infinite	    : true,
		});

		$slides.find('.bt-left').on('click',function(){
			$slides.find('.slides').slick('slickPrev');
		});

		$slides.find('.bt-right').on('click',function(){
			$slides.find('.slides').slick('slickNext');
		});
	}
}

function endVideo(e) {
	stopVideo();
}

function playVideo(){
	_introVIdeo.play();
	$('.video-intro').find('.wrapper').fadeOut();

}

function stopVideo(){
	_introVIdeo.pause();
	$('.video-intro').fadeOut();
	$homeWebdoor.find('.slides').slick('slickPlay');
}

function validateNewsletter() {
    var _toogle = false;

    $(this).find('form').validate({
        submitHandler: function(form) {
            
            if (!_toogle) {

                var datastring = $(form).serialize();

                $.ajax({
                    type: "POST",
                    url: BASE_URL + "frontend/Contact/newsletter/",
                    data: datastring,
                    dataType: "json",
                    success: function(data) {
                        console.log(data.result);

                        $('.newsletter').find('.inputs').hide();
						$('.newsletter').find('.message').html('<span>'+data.message+'</span>');
						$('.newsletter').find('.message').fadeIn(200).css('display', 'flex');

						setTimeout(function(){
							location.reload();
						},3000);
                    }
                });
                _toogle = true;
            }
        },
        ignore: '.ignore',
        errorElement: 'span',
        rules: {
            name: 'required',
            email: {
                required: true,
                email: true
            },
        },
        messages: {
            name: "Por favor digite seu nome.",
            email: {
                required: "Por favor digite seu email.",
                email: "Por favor digite um email válido."
            },
        },
        highlight: function ( element, errorClass, validClass ) {
            $(element).parent().addClass(errorClass);
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().removeClass(errorClass);
        },
    });
}


// ----------------------------------------- \\\
// ---------------- EXPORTS ---------------- \\\
// ----------------------------------------- \\\
export { init, resize }






