// ----------------------------------------- \\\
// ---------------- IMPORTS ---------------- \\\
// ----------------------------------------- \\\
import $ from "jquery";
import  "./modules/svg";


import * as Home from "./pages/home";
import * as Stores from "./pages/stores";
import * as Contact from "./pages/contact";
import * as ProductList from "./pages/product-list";
import * as ProductDetail from "./pages/product-detail";


import  './components/footer';
import  './components/header';
import  './components/video';


// ----------------------------------------- \\\
// ----------------- VARS ------------------ \\\
// ----------------------------------------- \\\
const _pages = $('main').data('page');



// ----------------------------------------- \\\
// ------------------ INIT ----------------- \\\
// ----------------------------------------- \\\
$(function() {
    "use strict";

    switch(_pages){
        case 'home':
            Home.init();
        break;
        case 'store':
            Stores.init();
        break;
        case 'contact':
            Contact.init();
        break;
        case 'products-list':
            ProductList.init();
        break;
        case 'product-detail':
            ProductDetail.init();
        break;
    }

    $( window ).resize(function() {
        switch(_pages){
            case 'home':
                Home.resize();
            break;
            case 'store':
                Stores.resize();
            break;
            case 'contact':
                Contact.resize();
            break;
            case 'products-list':
                ProductList.resize();
            break;
            case 'product-detail':
                ProductDetail.resize();
            break;
        }
    });

});
