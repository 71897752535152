// ----------------------------------------- \\\
// ---------------- IMPORTS ---------------- \\\
// ----------------------------------------- \\\
import $ from "jquery";
import JqueryValidate from "jquery-validation";
import JqueryMask from "jquery-mask-plugin";

// ----------------------------------------- \\\
// ----------------- VARS ------------------ \\\
// ----------------------------------------- \\\
var $contactForm    = $('form');

var _msgMorning 	= 'Bom Dia, \n';
var _msgAfternoon 	= 'Boa Tarde, \n';
var _msgNight 		= 'Boa Noite, \n';




// ----------------------------------------- \\\
// ------------------ INIT ----------------- \\\
// ----------------------------------------- \\\
function init(){


	$contactForm.find('[name="phone"]').mask('(00) 999 999 999');

    validateContact();

	$contactForm.find('input').on('keypress', function(e){
		if (e.which == 13) {
			$contactForm.submit();
			return false;
		}
	});

    setTimeout(animateType, 1000);

}





// ----------------------------------------- \\\
// ------------ PUBLIC FUNCIONS ------------ \\\
// ----------------------------------------- \\\
function resize() {}



// ----------------------------------------- \\\
// ------------ PRIVATE FUNCIONS ----------- \\\
// ----------------------------------------- \\\


function whatTimeIsIt(){

	var msg;
	var now = new Date();
	var hours = now.getHours();
	if (hours < 12) msg =_msgMorning;
	else if (hours < 18) msg = _msgAfternoon;
	else msg = _msgNight;
	return msg;

}

function animateType(){
	
	var input = $contactForm.find('textarea');
    whatTimeIsIt().split('').forEach(function(elem, index){
    	setTimeout(function(){
	        input.val(input.val() + elem);
	    }, index * 100); 
	    input.focus();
	});

}

function validateContact() {
    var _toogle = false;

    $('form').validate({
        submitHandler: function(form) {
            $(form).find('button').addClass('desactive');
            $(form).find('button').html('<span>AGUARDE...</span>');

            if (!_toogle) {

                var datastring = $(form).serialize();

                console.log('ajax send');

                $.ajax({
                    type: "POST",
                    url: BASE_URL + "frontend/Contact/send_contact/",
                    data: datastring,
                    dataType: "json",
                    success: function(data) {
                        console.log('ajax success');

                        $('.contact').find('.row').fadeOut();
						$('.contact').find('.message').html('<span>'+data.message+'</span>');
						$('.contact').find('.message').fadeIn(200).css('display', 'flex');

						setTimeout(function(){
							location.reload();
						},3000);
                    }
                });
                _toogle = true;
            }
        },
        ignore: '.ignore',
        errorElement: 'span',
        rules: {
            message: 'required',
            phone: {
                required: true,
                minlength: 15,
            },
            email: {
                required: true,
                email: true
            },
            
        },
        messages: {
            email: {
                required: "Por favor digite seu email.",
                email: "Por favor digite um email válido."
            },
            phone: {
                required: "Por favor digite seu Telefone.",
                minlength: "Número inválido."
            },
            message: "Por favor digite sua mensagem.",
        },
        highlight: function ( element, errorClass, validClass ) {
            $(element).parent().addClass(errorClass);
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().removeClass(errorClass);
        },
    });
}


// ----------------------------------------- \\\
// ---------------- EXPORTS ---------------- \\\
// ----------------------------------------- \\\
export { init, resize }






