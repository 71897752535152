// ----------------------------------------- \\\
// ---------------- IMPORTS ---------------- \\\
// ----------------------------------------- \\\
import $ from "jquery";
import 'slick-carousel';


// ----------------------------------------- \\\
// ----------------- VARS ------------------ \\\
// ----------------------------------------- \\\
var $productWebdoor      	= $('.product-webdoor');
var $productInspire      	= $('.product-inspiration');
var $productRelated      	= $('.product-related');

var $btTwitter				= $productWebdoor.find('.bt-twitter');
var $btFacebook				= $productWebdoor.find('.bt-facebook');
var $btPinterest			= $productWebdoor.find('.bt-pinterest');


// ----------------------------------------- \\\
// ------------------ INIT ----------------- \\\
// ----------------------------------------- \\\
function init(){

    // WEBDOOR
	$productWebdoor.find('.slides').slick({
		arrows		    : false,
		dots		    : false,
		infinite		: true,
		pauseOnHover	: false,
        speed			: 1200,
		autoplay        : true,   
		autoplaySpeed	: 5500,
	});

    $productWebdoor.find('.bt-left').on('click',function(){
        $productWebdoor.find('.slides').slick('slickPrev');
    });

    $productWebdoor.find('.bt-right').on('click',function(){
        $productWebdoor.find('.slides').slick('slickNext');
    });

	$productWebdoor.find('.slides').on( 'afterChange', function( e, slick, current ){
		var _percent = (current+1) * 50;
		$productWebdoor.find('.line').css('width', _percent+'%');
	});

	$productWebdoor.find('.slides').slick('slickGoTo', 0);

	if($productWebdoor.find('.slide').length == 1){
		$productWebdoor.addClass('no-slide');
	}
	


	// INSPIRATION
    $productInspire.find('.slides').slick({
		arrows		    : false,
		dots		    : false,
		infinite	    : true,
	});

    $productInspire.find('.bt-left').on('click',function(){
        $productInspire.find('.slides').slick('slickPrev');
    });

    $productInspire.find('.bt-right').on('click',function(){
        $productInspire.find('.slides').slick('slickNext');
    });



	// RELATED
    $productRelated.find('.product-list').slick({
		arrows		    : false,
		dots		    : false,
		infinite	    : true,
		infinite	    : true,
		variableWidth	: true,
		slidesToShow	: 3,
	});

    $productRelated.find('.bt-left').on('click',function(){
        $productRelated.find('.product-list').slick('slickPrev');
    });

    $productRelated.find('.bt-right').on('click',function(){
        $productRelated.find('.product-list').slick('slickNext');
    });


	$btTwitter.on('click',clickTwitter);
	$btFacebook.on('click', clickFacebook);
	$btPinterest.on('click',clickPinterest);

}





// ----------------------------------------- \\\
// ------------ PUBLIC FUNCIONS ------------ \\\
// ----------------------------------------- \\\
function resize() {}



// ----------------------------------------- \\\
// ------------ PRIVATE FUNCIONS ----------- \\\
// ----------------------------------------- \\\
function popup(url, width, height){

	var wLeft = window.screen.left || window.screenLeft || 0;
	var wTop = window.screen.top || window.screenTop || 0;
	var left = (window.screen.width / 2) - ((width / 2) + 10) + wLeft;
	var top = (window.screen.height / 2) - ((height / 2) + 50) + wTop;
	
	window.open(url, 'popup'+url, 'status=no, height=' + height + ', width=' + width + ', resizable=yes, left=' + left + ', top=' + top + ', screenX=' + left + ', screenY=' + top + ', toolbar=no, menubar=no, scrollbars=no, location=no, directories=no');

}


function clickTwitter(){

	var $button = $(this);
	var $share 	= $button.closest('.share');
	var _url 	= $button.data('url') || $share.data('url');
	var _text 	= $button.data('text') || $share.data('text');
	var _status = $button.data('status') || $share.data('status');

	shareOnTwitter(_url, _text, _status);

}

function shareOnTwitter(text, url, status){

	var $url 	= url || $('meta[property="og:url"]').attr('content') || location.href;
	var $text 	= text || $('meta[name="description"]').attr('content');
	var $status = status || [$text, $url].filter(function(i){i}).join(' ');

	popup('https://twitter.com/intent/tweet?status=' + $status, 600, 350);

}

function clickFacebook(){

	var $button = $(this);
	var $share 	= $button.closest('.share');
	var _url	= $button.data('url') || $share.data('url');

	shareOnFacebook(_url);

}

function shareOnFacebook(url){

	var $url = url || $('meta[property="og:url"]').attr('content') || location.href;

	popup('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent($url), 600, 350);

}

function clickPinterest(){

	var $button = $(this);
	var $share = $button.closest('.share');
	var _url = $button.data('url') || $share.data('url');

	shareOnPinterest(_url);

}

function shareOnPinterest(url){

	var $url = url || $('meta[property="og:url"]').attr('content') || location.href;

	popup('http://pinterest.com/pin/create/button/?url=' + encodeURIComponent($url), 600, 350);

}


// ----------------------------------------- \\\
// ---------------- EXPORTS ---------------- \\\
// ----------------------------------------- \\\
export { init, resize }



